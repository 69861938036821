@import "../../../scss/mixins";
@import "../../../scss/variables";

.listWrapper {
  display: grid;
  box-sizing: border-box;
  gap: 1rem;
  grid-template-columns: 1fr 1fr;
  overflow-y: auto;
  a {
    text-decoration: none;
  }
  &::-webkit-scrollbar {
    display: none;
  }
  @media (max-width: 667px) {
    grid-row-gap: 0.75rem;
    grid-column-gap: 0.5rem;
    justify-content: center;
  }
  @include breakpoint(0, $smScreen) {
    @include orientation(landscape) {
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }
  }
}
.listHorizontalWrapper {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  max-width: 100%;
  grid-column-gap: 1rem;
  overflow-x: auto;
  height: 100%;
  max-height: 100%;
  > a {
    text-decoration: none;
    width: 50%;
  }
  &::-webkit-scrollbar {
    display: none;
  }
  @media (max-width: 667px) {
    grid-column-gap: 0.5rem;
  }
  @include breakpoint(0, $smScreen) {
    @include orientation(landscape) {
      > a {
        width: 33%;
      }
    }
  }
}
