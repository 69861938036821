.container {
  height: 100%;
  display: flex;
  align-items: stretch;
  flex-direction: column;
  padding: 1.5rem;
  overflow-y: auto;

  &::-webkit-scrollbar {
    display: none;
  }
}
