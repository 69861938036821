.formControlWrapper {
  margin-bottom: 1.25rem;

  .label {
    color: var(--label-text-color);
    font-size: 1rem;
    font-family: var(--var-inter);
    font-weight: 400;
    margin-bottom: 0.75rem;
    display: inline-block;
  }

  .formControl {
    height: 52px;
    border-radius: 0.5rem;
    background: var(--input-background-color);
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
    overflow: hidden;
    box-sizing: border-box;

    &.isInvalid {
      border: 0.0625rem solid #dc3545;
    }

    span {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-inline-end: 0.5rem;
    }

    input {
      width: 100%;
      height: 100%;
      font-size: 1rem;
      font-weight: 400;
      color: var(--text-color);
      font-family: var(--var-inter);
      background: var(--input-background-color);
      outline: none;
      border: none;

      &::placeholder {
        font-size: 1rem;
        font-weight: 400;
        color: #8e8e8e;
        font-family: var(--var-inter);
      }

      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus,
      &:-webkit-autofill:active {
        -webkit-box-shadow: 0 0 0px 1000px var(--input-background-color) inset !important;
        -webkit-text-fill-color: var(--text-color);
      }

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        margin: 0;
        -webkit-appearance: none;
      }
    }

    svg {
      path {
        stroke: var(--icon-text-color);
      }
    }

    .eyeButton {
      border: none;
      outline: none;
      cursor: pointer;
      background-color: transparent;

      &.eyeSlash {
        svg {
          path {
            fill: var(--icon-text-color);
            stroke: initial;
          }
        }
      }
    }
  }

  @media (max-width: 667px) {
    margin-bottom: 1rem;

    .formControl {
      height: 46px;
      padding-left: 0.3125rem;
      padding-right: 0.3125rem;
      border-radius: 0.75rem;

      span {
        svg {
          transform: scale(0.9);
        }
      }

      input {
        font-size: 0.875rem;

        &::placeholder {
          font-size: 0.875rem;
        }
      }

      .eyeButton {
        svg {
          transform: scale(0.9);
        }
      }
    }
  }

  /* Disable 'zoom in' in mobile */
  input[type="color"],
  input[type="date"],
  input[type="datetime"],
  input[type="datetime-local"],
  input[type="email"],
  input[type="month"],
  input[type="number"],
  input[type="password"],
  input[type="search"],
  input[type="tel"],
  input[type="text"],
  input[type="time"],
  input[type="url"],
  input[type="week"],
  select:focus,
  textarea {
    font-size: 16px !important;
  }
}
