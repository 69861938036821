@import "../../../scss//mixins";
@import "../../../scss/variables";

.header {
  padding: 1rem 1.5rem;
  margin-bottom: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .buttonsContainer {
    display: flex;
    align-items: center;
    gap: 12px;
    }

  .arrowAndTextContainer {
    display: flex;
    align-items: center;
    flex: 1;
    gap: 0.5rem;

    p {
      font-size: 1.25rem;
      font-family: var(--var-inter);
      font-weight: 600;
      color: var(--header-text-color);
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      line-clamp: 1;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      text-align: initial;
      flex: 1;
    }
  }

  button {
    background-color: transparent;
    border: none;
    outline: none;
    display: inline-flex;
    align-items: center;

    svg {
      cursor: pointer;
    }
  }

  svg {
    path {
      stroke: var(--header-text-color);
    }
  }

  @include breakpoint(0, $smScreen) {
    @include orientation(landscape) {
      padding: 1rem 1.5rem;
    }
  }
}
