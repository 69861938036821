.container {
  .email {
    color: var(--primary-color);
  }
  button {
    margin-top: 4.375rem;
  }

  .resend {
    padding: 0.875rem 0;
    margin-top: 0;
    border: none;
    outline: none;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.25rem;

    color: var(--label-text-color);
    font-size: 1rem;
    font-weight: 400;

    span {
      color: var(--primary-color);
      text-decoration: underline;
      cursor: pointer;
    }
  }
}
