.dots {
  display: flex;
  align-items: center;
  gap: 0.25rem;
  .dot {
    height: 0.25rem;
    width: 0.25rem;
    background-color: #d9d9d9;
    border-radius: 50%;
    animation: bounce 1s infinite;
  }
}

.dot:nth-child(1) {
  animation-delay: 0s;
}

.dot:nth-child(2) {
  animation-delay: 0.1s;
}

.dot:nth-child(3) {
  animation-delay: 0.3s;
}

@keyframes bounce {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-0.333333333rem);
  }

  100% {
    transform: translateY(0);
  }
}
