@import "../../../scss/mixins";
@import "../../../scss/variables";

.meetUs {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.75rem;
  padding: 0.5rem 1rem;
  border-radius: 0 0 1rem 1rem;
  background-color: var(--footer-background-color);

  p {
    color: #8c8c8c;
    font-family: var(--var-inter);
    font-size: 0.875rem;
    font-weight: 400;
  }

  img {
    max-width: 100px;
    max-height: 20px;
    object-fit: contain;
  }

  @include breakpoint(0, $smScreen) {
    @include orientation(landscape) {
      border-radius: 0;
    }
  }
  @media (max-width: 667px) {
    border-radius: 0;
  }
}
