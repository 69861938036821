@import "../../scss/mixins";
@import "../../scss/variables";

.container
{
    width: 400px;
    height: 100%;
    border-radius: 1rem;
    background-color: var(--main-background-color);
    box-shadow: 0 0.25rem 2.0625rem 0 rgba(0, 0, 0, 0.15);

    @include breakpoint(0, $smScreen)
    {
        @include orientation(landscape) {
            right: 0;
            bottom: 0;
            width: 100vw;
            height: 100dvh !important;
            border-radius: 0;
        }
    }

    @media (max-width: 667px) {
        right: 0;
        bottom: 0;
        width: 100vw;
        height: 100dvh !important;
        border-radius: 0;
    }
}
