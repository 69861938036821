.messageWrapper {
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row-reverse;
}

.agentWrapper {
  justify-content: flex-start;
  flex-direction: row;
}

.avatarContainer {
  flex-shrink: 0;
}

.messageContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 6px;
  width: fit-content;
  padding: 0.5rem;
  border-radius: 0.5rem;
  background: var(--box-background-color);
  margin: 0.3125rem 0;
  border: none;
  outline: none;
  box-sizing: border-box;
  word-wrap: break-word;
  color: var(--text-color);
  font-family: var(--var-inter);
  font-size: 1rem;
  font-weight: 400;
  word-break: break-word;
  max-width: 90%;
  text-align: initial;

  img {
    width: 150px;
    height: 150px;
    object-fit: contain;
    background: #f1f3fc;
    border-radius: 8px;
    margin: 8px 0px;
    display: block;
  }

  .attachmentContainer {
    display: flex;
    gap: 4px;
    align-items: center;
    flex-wrap: wrap;

    img {
      width: 150px;
      height: 150px;
      object-fit: contain;
      background: #f1f3fc;
      border-radius: 8px;
    }
  }

  a {
    color: var(--primary-color);
  }

  .messageDate {
    color: var(--first-message-text-color);
    font-family: var(--var-inter);
    font-weight: 400;
    font-size: 11px;
    display: flex;
    gap: 4px;
    align-items: center;
    flex-shrink: 0;
    align-self: flex-end;

    .seenMessageContainer {
      svg {
        width: 15px;
        height: 15px;
        fill: var(--first-message-text-color);
        flex-shrink: 0;
      }
      .seenMessage {
        display: flex;
        align-items: center;
        flex-shrink: 0;
        svg {
          fill: white;
          &:last-child {
            margin-left: -9px;
          }
        }
      }
    }
  }
}

.agentContainer {
  justify-content: flex-start;
}

.userContainer {
  background: var(--chat-message-background-color);
  color: #fff;
  align-self: flex-end !important;
}

.productCard {
  background: white;
  flex-grow: 1;
  display: flex;
  justify-content: center;
}

.productsContainer {
  display: flex;
  gap: 12px;
  flex-wrap: wrap;
}

.imgCardContainer {
  width: 100%;
}

.spinner {
  width: 144px;
  height: 209px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.arabicText {
  align-self: flex-end;
}
