.avatar {
  width: 40px;
  height: 40px;
  font-family: var(--var-inter);

  .avatarImg,
  .avatarPlaceholder {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    line-height: 24.5px;
    text-transform: uppercase;
    border-radius: 50%;
    box-sizing: border-box;
  }
}
