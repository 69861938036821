@import "../../../scss/mixins";
@import "../../../scss/variables";

.metaverseContainer {
  flex: 1;
  padding: 16px;
  overflow-y: auto;
  display: grid;
  grid-template-columns: 1fr;
  align-content: flex-start;
  &::-webkit-scrollbar {
    display: none;
  }

  &.isLoggedIn {
    padding-top: 0;
  }

  @include breakpoint(0, $smScreen) {
    @include orientation(landscape) {
      gap: 0.5rem;
      grid-template-columns: 1fr 1fr;
    }
  }

  @include breakpoint(0, 550px) {
    @include orientation(landscape) {
      grid-template-columns: 1fr;
    }
  }
}
