@import "../../../scss/mixins";
@import "../../../scss/variables";

.header {
  padding: 1.5rem;
  background-color: var(--primary-color);
  border-radius: 1rem 1rem 0 0;
  color: #fff;
  position: relative;

  .top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1.5rem;
    position: relative;
    z-index: 1;

    .buttonsContainer {
        display: flex;
        align-items: center;
        gap: 12px;
    }

    .arrowAndTextContainer {
      display: flex;
      align-items: center;
      flex: 1;
      gap: 0.5rem;

      p {
        font-size: 1.25rem;
        font-family: var(--var-inter);
        font-weight: 600;
        color: #fff;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        line-clamp: 1;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        text-align: initial;
        flex: 1;
      }
    }

    button {
      background-color: transparent;
      border: none;
      outline: none;
      display: inline-flex;
      align-items: center;

      svg {
        cursor: pointer;
        path {
          stroke: #fff;
        }
      }
    }
  }

  .bottom {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 0.525rem;
    position: relative;
    z-index: 1;

    .text {
      text-align: center;

      h4 {
        color: #fff;
        font-family: var(--var-inter);
        font-size: 1.125rem;
        font-weight: 600;
        margin-bottom: 0.5rem;
      }

      p {
        font-family: var(--var-inter);
        margin-bottom: 0;
        color: #fff;
        font-size: 0.875rem;
        font-weight: 400;
      }
    }
  }

  .orgCover {
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    border-radius: 1rem 1rem 0 0;
    opacity: 0.2;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  @include breakpoint(0, $smScreen) {
    @include orientation(landscape) {
      border-radius: 0;

      .orgCover {
        border-radius: 0;
      }
      padding: 1rem 1.5rem;
      .top {
        margin-bottom: 0;
      }

      .bottom {
        display: none;
      }
    }
  }
  @media (max-width: 667px) {
    border-radius: 0;

    .orgCover {
      border-radius: 0;
    }
  }
}
