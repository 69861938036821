@import "../../../scss/mixins";
@import "../../../scss/variables";

.wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 16px;

  & p {
    width: 162px;
    text-align: center;
    font-family: var(--var-inter);
    color: #8C8C8C;
    font-size: 14px;
    font-weight: 400;
  }

  & svg circle {
    fill: var(--primary-color);
  }

  & svg path {
    &:nth-child(3) {
    fill: var(--primary-color);
    }
  }

  @include breakpoint(0, $smScreen) {
    > svg {
      width: 80%;
      height: 80%;
    }
  }
}
