@import "../../../scss/mixins";
@import "../../../scss/variables";

.tabsContainer
{
    padding-top: 12px;

    .tabs {
        display: grid;
        box-sizing: border-box;
        gap: 0.75rem;
        grid-template-columns: 1fr 1fr 1fr;
    }

    .pointsText {
        color: #909090;
        font-size: 0.75rem;
        font-family: var(--var-inter);
        font-weight: 400;
    }

    .popup
    {
        position: absolute;
        left: calc(-100% - 1rem);
        top: 50%;
        transform: translateY(-50%);
        width: 400px;
        height: 300px;
        padding: 1rem 1.5rem 1.5rem 1.5rem;
        border-radius: 1rem;
        background: var(--main-background-color);
        box-shadow: 0 0.25rem 2.0625rem 0 rgba(0, 0, 0, 0.15);
        box-sizing: border-box;
    }

    @include breakpoint(0, $smScreen)
    {
        .popup {
            display: none;
        }

        @include orientation(landscape) {
            .tabs {
                grid-template-columns: 1fr 1fr 1fr;
            }
        }
    }

    &.homeWithoutMessageStyles {
        padding-top: 0;

        .tabs {
            display: grid;
            box-sizing: border-box;
            gap: 0.75rem;
            grid-template-columns: 1fr 1fr;
        }

        @include breakpoint(0, $smScreen)
        {
    
            @include orientation(landscape) {
                .tabs {
                    grid-template-columns: 1fr 1fr 1fr 1fr;
                }
            }
        }
    }
}
