.productCard {
  display: inline-block;
  text-decoration: none;
  font-family: var(--var-inter);
  border-radius: 0.25rem;
  box-sizing: border-box;
  .imageContainer {
    position: relative;
    height: 129px;
    background-color: #fff;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0.75rem;
    overflow: hidden;
    border: 1px solid #dedede;
    border-radius: 4px;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
    .discount,
    .promotions {
      max-width: 80px;
      position: absolute;
      right: 0;
      top: 0;
      padding: 0.25rem 0.3125rem;
      background: #e44141;
      color: #fff;
      font-family: var(--var-inter);
      font-size: 0.75rem;
      font-weight: 600;
      border-top-right-radius: 0.25rem;
    }
    .promotions {
      background: #3db448;
    }
    .has360 {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      left: 0.5rem;
      bottom: 0.5rem;
      padding: 0.125rem;
      background: #fff;
      border-radius: 0.25rem;
      box-shadow: 0 0.0625rem 0.25rem 0 rgba(0, 0, 0, 0.25);

      svg {
        width: 22px;
        height: 22px;
      }
    }
  }

  h4 {
    color: var(--header-text-color);
    font-size: 0.875rem;
    font-weight: 500;
    margin-bottom: 0.5rem;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }

  .rating {
    margin-bottom: 0.75rem;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    color: #323232;
    font-size: 0.75rem;
    font-weight: 500;
    display: flex;
    align-items: center;
    gap: 0.25rem;
  }
  .price {
    color: var(--primary-color);
    font-size: 0.875rem;
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    .discount {
      color: #9f9f9f;
      font-weight: 500;
      text-decoration-line: line-through;
      margin-left: 0.5rem;
    }
  }

  &.isSm {
    min-width: 158px;
    max-width: 158px;
    .imageContainer {
    }
  }

  &.popupCard {
    .imageContainer {
      height: 96px;
    }
  }
}
