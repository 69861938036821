.wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
}

.message {
  margin: 0;
  font-size: 1rem;
  font-weight: 500;
  font-family: var(--var-poppins);
  color: var(--text-color);
}
