.authText {
  font-family: var(--var-inter);
  color: var(--text-color);
  text-align: center;
  margin-bottom: 2rem;

  h4 {
    font-size: 1.25rem;
    font-weight: 600;
    margin-bottom: 0.5rem;
  }

  p {
    font-size: 0.875rem;
    font-weight: 400;
  }
}
