@import "../../../scss/mixins";
@import "../../../scss/variables";

.eventContainer
{
    flex: 1;
    padding: 1rem 1.5rem;
    overflow-y: auto;

    &::-webkit-scrollbar {
        display: none;
    }

    .imgContainer
    {
        width: 100%;
        height: 192px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 0.25rem;
        box-sizing: border-box;
        margin-bottom: 1.375rem;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 0.25rem;
        }
    }

    .content
    {
        h3 {
            color: var(--header-text-color);
            font-size: 1.5rem;
            font-family: var(--var-inter);
            font-weight: 600;
            margin-bottom: 1.125rem;
        }

        p
        {
            display: flex;
            align-items: center;
            gap: 0.5rem;
            color: var(--header-text-color);
            font-size: 1rem;
            font-family: var(--var-inter);
            font-weight: 400;
            margin-bottom: 0.75rem;

            svg {
                * {
                    stroke: var(--primary-color);
                }
            }

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    .hosts
    {
        position: relative;
        display: flex;
        align-items: center;
        gap: 1rem;
        overflow-x: auto;

        &::-webkit-scrollbar {
            display: none;
        }

        .host
        {
            display: flex;
            align-items: center;
            gap: 0.75rem;
            padding: 1.4rem 0;
            min-width: 180px;

            img,
            span {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 40px;
                height: 40px;
                border-radius: 50%;
                background-color: var(--main-background-color);
                object-fit: contain;
                color: var(--text-color);
                background: white;
            }

            span {
                font-family: var(--var-inter);
                text-transform: uppercase;
                border: 0.0625rem solid #8b8b8b;
                box-sizing: border-box;
            }

            .influencerInfo
            {
                font-family: var(--var-inter);
                font-size: 1rem;
                flex: 1;

                h4 {
                    color: var(--text-color);
                    font-weight: 600;
                    margin-bottom: 0.25rem;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                }

                p {
                    color: #8b8b8b;
                    font-weight: 400;
                }
            }
        }

        &::after {
            content: "";
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 0.0625rem;
            background: var(--hr-color);
        }
    }

    .description
    {
        padding: 1.5rem 0 0 0;
        color: var(--first-message-text-color);
        font-size: 1rem;
        font-family: var(--var-inter);
        font-weight: 400;
        line-height: 1.4rem;
        margin-bottom: 2.25rem;
    }

    .joinEvent
    {
        width: 100%;
        height: 46.16px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 1;
        padding: 0.88rem 1rem;
        border: none;
        border-radius: 0.625rem;
        outline: none;
        cursor: pointer;
        background-color: var(--primary-color);
        color: #fff;
        font-size: 0.9375rem;
        font-family: var(--var-inter);
        font-weight: 600;
        margin: auto;

        &:disabled {
            cursor: not-allowed;
            opacity: 0.5;
        }
    }

    @include breakpoint(0, $smScreen)
    {
        @include orientation(landscape)
        {
            .container
            {
                display: flex;
                gap: 1.5rem;
                position: relative;

                &::after {
                    content: "";
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    width: 100%;
                    height: 0.0625rem;
                    background: var(--hr-color);
                }

                .imgContainer {
                    width: 45%;
                }

                .right
                {
                    width: 55%;

                    .content
                    {
                        h3 {
                            margin-bottom: 0.875rem;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            display: -webkit-box;
                            -webkit-line-clamp: 1;
                            -webkit-box-orient: vertical;
                        }

                        p
                        {
                            margin-bottom: 0.625rem;

                            &:last-child {
                                margin-bottom: 0;
                            }
                        }
                    }

                    .hosts
                    {
                        .host
                        {
                            padding: 1rem 0 24px;
                        }

                        &::after {
                            display: none;
                        }
                    }
                }
            }

            .description {
                padding-top: 1rem;
            }
        }
    }
}
