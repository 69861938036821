.forgetPasswod
{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 2rem;

    a {
        display: block;
        color: var(--label-text-color);
        font-size: 1rem;
        font-weight: 400;
        font-family: var(--var-inter);
        text-decoration: none;
    }
}
