.container {
    flex: 1;
    padding: 3.5rem 1.5rem 0;
    display: flex;
    align-items: center;
    flex-direction: column;
    font-family: var(--var-inter);
    gap: 1rem;

    p {
        color: var(--text-color);
        text-align: center;
        font-size: 1.25rem;
        font-weight: 600;
    }

    button {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        padding: 0.875rem 1rem;
        color: #fff;
        background-color: var(--primary-color);
        border-radius: 0.625rem;
        border: none;
        outline: none;
        font-size: 1rem;
        font-weight: 600;
        cursor: pointer;
    }
}
