.button {
  border: none;
  outline: none;
  cursor: pointer;
  padding: 0.5rem 1.75rem;
  text-align: center;
  background-color: transparent;
  border: 0.0625rem solid transparent;
}

.contained {
  font-size: 1rem;
  color: #fff;
  font-weight: 600;
  padding: 0.875rem 1rem;
  margin-bottom: 0.5rem;
  border-radius: 0.625rem;
  font-family: var(--var-inter);
  background-color: var(--primary-color);

  @media (max-width: 667px) {
    padding: 0.75rem 1.625rem;
    font-size: 0.875rem;
  }
}

.outlined {
  font-size: 1rem;
  font-weight: 600;
  padding: 0.9375rem 2rem;
  margin-bottom: 1.325rem;
  border-radius: 0.625rem;
  background-color: #fff;
  color: var(--primary-color);
  font-family: var(--var-inter);
  border: 0.0625rem solid var(--primary-color);

  @media (max-width: 667px) {
    padding: 0.75rem 1.625rem;
    font-size: 0.875rem;
  }
}

.text {
  font-size: 1.125rem;
  font-weight: 500;
  color: var(--var-text);
  font-family: var(--var-manrope);

  @media (max-width: 667px) {
    font-size: 1rem;
  }
}
