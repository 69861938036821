:root {
  --var-manrope: "Manrope", Helvetica, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  --var-poppins: "Poppins ", Helvetica, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  --var-inter: "Inter", Helvetica, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  --var-appWidth: 463px;
  --var-appHeight: 578px;
  --var-primary: #206cee;
  --var-text: #0c207380;

  --main-background-color: #fff;
  --box-background-color: #f5f5f5;
  --sub-accordion-background-color: rgba(var(--primary-color-rgb), 0.1);
  --input-background-color: #f5f5f5;
  --metaverse-card-background-color: #fff;
  --metaverse-card-border-color: #dedede;
  --tab-background-color: #fff;
  --footer-background-color: #fafafa;
  --tab-border-color: #e6e6e6;
  --tab-icon-color: var(--primary-color);
  --product-card-background-color: #fff;
  --product-card-border-color: #cacaca;
  --background-color-and-transparent: #fff;
  --background-color-and-transparent-border-color: #cacaca;
  --main-background-color-for-button: #1d1d1d;
  
  --chat-message-background-color: #000;
  
  --text-color: #121212;
  --header-text-color: #323232;
  --first-message-text-color: #666;
  --popup-title-color: #323232;
  --popup-content-color: #7b7b7b;
  --virtual-text-color: #7e7e7e;
  --products-tab-text-color: #a8a8a8;
  --icon-text-color: #323232;
  --label-text-color: #8e8e8e;
  --typing-text-color: #000;
  --toggle-menu-text-color: #000;
  
  --main-border-color: #e1e1e1;
  
  --hr-color: linear-gradient(
    270deg,
    rgba(222, 222, 222, 0.13) 0%,
    #dedede 52.99%,
    rgba(222, 222, 222, 0) 100%
    );

  --tab-border: 1px solid #e6e6e6;
}

body[data-theme="dark"] {
  --main-background-color: #292929;
  --box-background-color: #343434;
  --sub-accordion-background-color: rgba(var(--primary-color-rgb), 0.4);
  --input-background-color: #343434;
  --metaverse-card-background-color: #282a2d;
  --metaverse-card-border-color: #282a2d;
  --tab-background-color: #5453545e;
  --footer-background-color: #292929;
  --tab-border-color: #ffffff99;
  --tab-icon-color: #9F9F9F;
  --chat-message-background-color: rgba(var(--primary-color-rgb), 0.4);
  --product-card-background-color: #383838;
  --product-card-border-color: #383838;
  --background-color-and-transparent: transparent;
  --background-color-and-transparent-border-color: #4e4f51;
  --main-background-color-for-button: linear-gradient(
    90deg,
    #9314fe 3.54%,
    #4e0a93 103.52%
  );

  --text-color: #fff;
  --header-text-color: #fff;
  --first-message-text-color: #a3a2a4;
  --popup-title-color: #fff;
  --popup-content-color: #fff;
  --virtual-text-color: #a3a2a4;
  --products-tab-text-color: #a3a2a4;
  --icon-text-color: #fff;
  --label-text-color: #fff;
  --typing-text-color: #959595;
  --toggle-menu-text-color: #fff;


  --main-border-color: #4e4f51;

  --hr-color: linear-gradient(
    270deg,
    #1a1d1f 0%,
    #4e4f51 53.89%,
    #1a1d1f 101.7%
  );

  --tab-border: 1px solid #ffffff99;
}

.app {
  h1 {
    font-family: var(--var-manrope);
  }
  h2 {
    font-family: var(--var-poppins);
  }
  h3 {
    font-family: var(--var-inter);
  }
}

.base {
  right: 35px;
  bottom: 56px;
  position: absolute;

  .baseInner {
    position: relative;
  }
}

.smBase {
  right: 10px;
  bottom: 10px;
}
