.chatContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 0.3125rem;
    height: 0.3125rem;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background-color: var(--primary-color);
    border-radius: 0.15625rem;
  }
  .chatInputContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0.75rem;
    border-top: 0.0625rem solid var(--main-border-color);
    padding: 1.25rem 1rem;

    .chatInput {
      display: inline-flex;
      flex: 1;
      border: none;
      outline: none;
      box-sizing: border-box;
      padding: 0.85rem 0.75rem;
      border-radius: 0.5rem;
      background: var(--box-background-color);
      color: var(--text-color);
      font-family: var(--var-inter);
      font-size: 1rem;
      font-weight: 400;
      &:focus {
        font-size: 1rem;
      }
      &.isArabicText {
        direction: rtl;
        unicode-bidi: embed;
        text-align: right;
      }
    }

    button {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0.5rem;
      gap: 0.5rem;
      border: none;
      outline: none;
      cursor: pointer;
      border-radius: 0.375rem;
      background: var(--primary-color);
      width: 46px;
      height: 46px;

      div {
        border-color: #fff transparent transparent transparent;
        width: 24px;
        height: 24px;
      }
    }

    .disabledBtn {
        cursor: not-allowed;
    }
  }
  .chatDataInfo {
    display: flex;
    flex-direction: column;
    height: 80%;
    width: 100%;
    box-sizing: border-box;
    overflow-y: auto;
    padding: 0 1rem;
    padding-top: 1rem;
    flex: 1;
    .messages {
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: 12px;
      padding-bottom: 16px;

      .timeOfFirstMessage {
        margin: 0;
        color: #666666;
        font-size: 12px;
        font-weight: 400;
        text-align: center;
        font-family: var(--var-inter);
        margin-bottom: 12px;
      }

      .typing {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        margin: 0.3125rem 0;
        p {
          color: var(--typing-text-color);
          font-family: var(--var-inter);
          font-size: 0.875rem;
          font-weight: 400;
        }
      }

      .messageContainer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 6px;
        width: fit-content;
        padding: 0.5rem;
        border-radius: 0.5rem;
        background: var(--box-background-color);
        margin: 0.3125rem 0;
        border: none;
        outline: none;
        box-sizing: border-box;
        word-wrap: break-word;
        color: var(--text-color);
        font-family: var(--var-inter);
        font-size: 1rem;
        font-weight: 400;
        word-break: break-word;
        max-width: 90%;
        text-align: initial;

        img {
            width: 150px;
            height: 150px;
            object-fit: contain;
            background: #f1f3fc;
            border-radius: 8px;
            margin: 8px 0px;
            display: block;
        }

        .attachmentContainer {
            display: flex;
            gap: 4px;
            align-items: center;
            flex-wrap: wrap;
      
            img {
              width: 150px;
              height: 150px;
              object-fit: contain;
              background: #f1f3fc;
              border-radius: 8px;
            }
          }

        a {
            color: var(--primary-color);
        }

        .messageDate {
          color: var(--first-message-text-color);
          font-family: var(--var-inter);
          font-weight: 400;
          font-size: 11px;
          display: flex;
          gap: 4px;
          align-items: center;
          flex-shrink: 0;
          align-self: flex-end;

          .seenMessageContainer {
            svg {
              width: 15px;
              height: 15px;
              fill: var(--first-message-text-color);
              flex-shrink: 0;
            }
            .seenMessage {
              display: flex;
              align-items: center;
              flex-shrink: 0;
              svg {
                fill: white;
                &:last-child {
                  margin-left: -9px;
                }
              }
            }
          }
        }
      }

      .agentContainer {
        justify-content: flex-start;
      }

      .userContainer {
        background: var(--chat-message-background-color);
        color: #fff;
        align-self: flex-end !important;
      }
    }

    .chattingTime {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 1rem;
      p {
        color: var(--first-message-text-color);
        font-family: var(--var-inter);
        font-size: 0.875rem;
        font-weight: 400;
      }
    }
    .agentMessage,
    .userMessage {
      display: flex;
      justify-content: center;
      align-items: center;
      width: fit-content;
      padding: 0.5rem;
      border-radius: 0.5rem;
      background: var(--box-background-color);
      margin: 0.3125rem 0;
      border: none;
      outline: none;
      box-sizing: border-box;
      word-wrap: break-word;
      color: var(--text-color);
      font-family: var(--var-inter);
      font-size: 1rem;
      font-weight: 400;
      word-break: break-word;
      max-width: 80%;
      text-align: initial;

      &.isArabicText {
        direction: rtl;
        unicode-bidi: embed;
        text-align: right;
      }
    }

    .userMessage {
      background: var(--chat-message-background-color);
      color: #fff;
      align-self: flex-end !important;
    }

    .toggleMenu {
      display: flex;
      justify-content: center;
      margin-top: 1rem;

      button {
        border: none;
        outline: none;
        width: auto;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        padding: 0.25rem 0.75rem;
        gap: 0.125rem;
        border-radius: 2.1875rem;
        background: rgba(var(--primary-color-rgb), 0.1);
        margin-bottom: 1rem;
        color: var(--toggle-menu-text-color);
        font-family: var(--var-inter);
        font-size: 0.875rem;
        font-weight: 400;
        cursor: pointer;

        svg {
          path {
            stroke: var(--toggle-menu-text-color);
          }
        }
      }
    }
  }
  
  .chatDataWithMessage {
    padding-bottom: 1rem;
  }
}
