@import url("https://fonts.googleapis.com/css2?family=Inter&family=Manrope:wght@400;500;600&family=Poppins:wght@300;400;500;600&display=swap");
@import "./scss/mixins";
@import "./scss/variables";

html {
  font-size: 16px;
}

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  margin: 0;
  padding: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#nasnavapp {
  @include breakpoint(0, $smScreen) {
    @include orientation(landscape) {
      position: relative;
      height: 100dvh;
      width: 100vw;
    }
  }
  @media (max-width: 667px) {
    position: relative;
    height: 100dvh;
    width: 100vw;
  }
}
