@import "../../scss/mixins";

.sideServiceApp {
  display: inline-block;
  width: auto;
  height: auto;
  outline: none;
  border: none;
  background-color: transparent;
  position: absolute;
  right: 0%;
  top: 50%;
  transform: translate(-50%, -50%);

  .buttonsContainer {
    display: flex;
    flex-direction: column;
    gap: 12px;

    & button {
      width: 48px;
      height: 48px;
      border-radius: 50%;
      background-color: var(--primary-color);
      color: white;
      box-shadow: 0px 4px 15px 0px var(--sub-accordion-background-color);
      cursor: pointer;
      outline: none;
      border: none;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: all 0.3s ease-in-out;
      position: relative;

      &:hover {
        background-color: var(--primary-color);
        @include darken-var(var(--primary-color), 20%);
      }

      & svg {
        width: 28px;
        height: 28px;
        & path {
          stroke: white;
        }
      }

      .toolTip {
        position: absolute;
        background: #000;
        color: white;
        padding: 4px 8px 6px 8px;
        top: auto;
        right: 58px;
        font-family: var(--var-inter);
        font-size: 14px;
        font-weight: 500;
        text-wrap: nowrap;
        border-radius: 8px;
        text-transform: capitalize;
        animation: vibration 1.5s alternate infinite;
      }
    }
  }
}

@keyframes vibration
{
    from {
        transform: scale(0.9);
    }

    to {
        transform: scale(1);
    }
}
