.serviceAppIcon
{
    display: inline-block;
    width: auto;
    height: auto;
    outline: none;
    border: none;
    background-color: transparent;
    position: absolute;
    bottom: 0;
    right: 0;

    .orgContent
    {
        position: relative;
        width: 254px;
        height: 64px;
        display: flex;
        align-items: center;
        gap: 12px;
        box-sizing: border-box;
        border-radius: 41px;
        padding: 8px 24px 8px 8px;
        background-color: var(--primary-color);
        box-shadow: 0 14px 21px 0 rgba(var(--primary-color-rgb), 0.21);
        cursor: pointer;
        animation: vibration 1.5s alternate infinite;

        img {
            width: 48px;
            height: 48px;
            border-radius: 50%;
            background-color: #fff;
            object-fit: contain;
            flex-shrink: 0;
        }

        p {
            color: #fff;
            font-size: 18px;
            font-family: var(--var-inter);
            font-weight: 700;
            margin: 0;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            text-align: initial;
        }

        .backDrop {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            opacity: 0.6;
            border-radius: 41px;
            background-color: var(--primary-color);
            z-index: -1;
            animation: vibrationForBackDrop 1.5s alternate infinite;
        }
    }

    @media (max-width: 667px)
    {
        .orgContent
        {
            width: 190px;
            height: 48px;
            gap: 9px;
            border-radius: 31px;
            padding: 6px 18px 6px 6px;

            img {
                width: 36px;
                height: 36px;
            }

            p {
                font-size: 13.5px;
            }

            .backDrop {
                border-radius: 31px;
            }
        }
    }
}

.falgHelpMsg
{
    position: absolute;
    background-color: #121212;
    padding: 8px 16px;
    border-radius: 14px;
    color: #fff;
    font-family: var(--var-inter);
    font-weight: 600;
    top: -63px;
    right: 0;
    font-size: 20px;
    cursor: pointer;

    .trangle {
        width: 0;
        height: 0;
        border-top: 8px solid transparent;
        border-left: 10px solid #121212;
        border-bottom: 8px solid transparent;
        position: absolute;
        right: 28px;
        bottom: -13px;
        transform: rotate(90deg);
    }

    .typewriter {
        overflow: hidden;
        white-space: nowrap;
        border-right: 0.15em solid var(--primary-color);
        margin: 0 auto;
        letter-spacing: 0.15em;
        animation: typing 5s steps(40, end) infinite,
        blink-caret 0.75s step-end infinite;
    }

    @media (max-width: 667px)
    {
        padding: 6px 12px;
        border-radius: 10.5px;
        top: -47px;
        font-size: 15px;

        .trangle {
            right: 21px;
            bottom: -12px;
        }
    }
}

@keyframes typing
{
    from {
        width: 0;
    }

    to {
        width: 100%;
    }
}

@keyframes blink-caret
{
    from,
    to {
        border-color: transparent;
    }

    50% {
        border-color: var(--primary-color);
    }
}

@keyframes vibration
{
    from {
        transform: scale(0.9);
    }

    to {
        transform: scale(1);
    }
}

@keyframes vibrationForBackDrop
{
    from {
        transform: scale(0.9);
        opacity: 0.6;
    }

    to {
        transform: scale(1.2);
        opacity: 0;
    }
}
