@import "../../../../scss/mixins";
@import "../../../../scss/variables";

.tab
{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    padding: 0.75rem;
    height: 105px;
    background: var(--tab-background-color);
    box-sizing: border-box;
    text-align: center;
    border-radius: 0.5625rem;
    border: var(--tab-border);
    flex: 1;
    transition: all ease-in-out 0.3s;
    color: var(--text-color);
    font-size: 0.875rem;
    font-family: var(--var-inter);
    font-weight: 500;
    cursor: pointer;
    text-decoration: none;
    position: relative;

    @media (max-width: 374.99px) {
        font-size: 13px;
        padding: 6px;
    }

    &:hover {
        box-shadow: 0 0.25rem 0.6875rem 0px rgba(0, 0, 0, 0.14);
        border-color: var(--primary-color);
    }
    svg {
        flex-shrink: 0;
        * {
            stroke: var(--tab-icon-color);
        }
    }
}
