.backdrop {
  inset: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  position: absolute;
  border-radius: 1rem;
  background: rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
}
