@import "../../../../scss/mixins";
@import "../../../../scss/variables";

.contentAuthHeader {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  font-family: var(--var-inter);
  padding-top: 0.75rem;
  text-align: center;

  .imgContainer {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    box-sizing: border-box;
    background-color: #fff;
    margin-bottom: 0.625rem;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  h4 {
    color: #fff;
    font-size: 1.25rem;
    font-weight: 600;
    margin-bottom: 0.5rem;
  }

  p {
    color: #fff;
    font-size: 0.875rem;
    font-weight: 400;
  }

  @include breakpoint(0, $smScreen) {
    h4 {
      font-size: 1rem;

    }

    p {
      font-size: 0.8rem;
    }
    
    @include orientation(landscape) {
      display: none;
    }
  }
}
