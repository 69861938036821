.tabsContainer {
  margin-bottom: 16px;
  .tabs {
    display: flex;
    align-items: center;
  }
}

.noMargin {
  margin-bottom: 0;
}
