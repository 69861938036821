@import "../../scss/mixins";
@import "../../scss/variables";

.detailsContainer {
  @include breakpoint(0, $smScreen) {
    @include orientation(landscape) {
      position: relative;
      display: flex;
      gap: 1.5rem;
      padding-bottom: 0.75rem;

      > div {
        &:first-of-type {
          width: 45%;
        }
        &:last-of-type {
          width: 55%;
        }
      }

      &::after {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 0.0625rem;
        background: var(--hr-color);
      }
    }
  }
}
