@import "../../../scss/mixins";
@import "../../../scss/variables";

.modal {
  z-index: 9999;
  width: 100dvw;
  height: 100dvh;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: flex-end;
  backdrop-filter: blur(15px);
  -webkit-backdrop-filter: blur(15px);
  overflow: hidden;

  header {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    z-index: 1;
    background-color: var(--main-background-color);
    &.withoutBackgroundHeader {
      background-color: unset;
    }
  }

  .closeButton {
    background-color: transparent;
    border: none;
    outline: none;
    display: inline-flex;
    align-items: center;

    svg {
      cursor: pointer;
      height: 20px;
      width: 20px;
      path {
        stroke: var(--header-text-color);
      }
    }
  }

  .title {
    font-size: 1rem;
    font-family: var(--var-inter);
    font-weight: 600;
    color: var(--header-text-color);
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
}
