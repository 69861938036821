.stripe {
  display: flex;
  padding: 0.5rem 1.5rem;
  align-items: center;
  gap: 0.5625rem;
  background: rgba(218, 144, 0, 0.08);

  p {
    color: #bf7a12;
    font-family: var(--var-inter);
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.375rem;
    a {
      color: inherit;
      font-weight: 600;
      text-decoration-line: underline;
    }
  }
}
