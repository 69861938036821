.popupContentHeader
{
    margin-bottom: 1.5rem;

    h3 {
        margin-bottom: 0.25rem;
        color: var(--popup-title-color);
        font-size: 1.25rem;
        font-family: var(--var-inter);
        font-weight: 600;
    }

    p {
        color: var(--popup-content-color);
        font-family: var(--var-inter);
        font-size: 1rem;
        font-weight: 400;
    }
}
