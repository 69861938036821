.tab {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  padding: 0.5rem 1rem;
  border-bottom: 0.0625rem solid #a8a8a8;
  background: var(--main-background-color);
  cursor: pointer;
  color: var(--products-tab-text-color);

  font-family: var(--var-inter);
  font-size: 1rem;
  font-weight: 600;
  transition: all ease-in-out 0.3s;
  &.active {
    border-bottom: 0.125rem solid var(--primary-color);
    color: var(--primary-color);
  }
}
