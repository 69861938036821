.homeWithoutMessage {
  height: 100%;
  width: 100%;
  padding: 1.5rem;
  padding-top: 0;
  box-sizing: border-box;
  overflow-y: auto;

  &::-webkit-scrollbar {
    display: none;
  }
}
