.wrapper {
  margin-top: 1.375rem;

  h2 {
    margin-top: 0;
    margin-bottom: 1.375rem;
    color: var(--header-text-color);
    font-size: 1.125rem;
    font-family: var(--var-poppins);
    font-weight: 500;
  }
}
