.accordion {
  font-family: var(--var-inter);
  &:last-child {
    > div > div {
      border: none;
    }
  }
}

.subAccordionItem {
  font-family: var(--var-inter);
  transition: all ease-in-out 0.3s;
  padding: 0.75rem 1rem;

  &.active {
    background: var(--sub-accordion-background-color);
    border-radius: 0.25rem;
    margin: 0.75rem 0;
  }
}
