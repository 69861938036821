.otpContainer {
  display: flex;
  align-items: center;
  justify-content: center;

  .otpInput {
    height: 40px;
    width: 36px !important;
    background: var(--box-background-color);
    border-radius: 0.5rem;
    font-family: var(--var-poppins);
    padding: 0.375rem;
    font-size: 1.375rem;
    font-weight: 600;
    border: none;
    outline: none;
    color: var(--text-color);
  }

  .errorInput {
    height: 38px;
    width: 34px !important;
    border: 0.0625rem solid #dc3545;
  }

  .sepertator {
    display: block;
    margin-inline: 0.4375rem;
  }

  @media (max-width: 667px) {
    .otpInput {
      height: 30px;
      font-size: 1rem;
      width: 26px !important;
      border-radius: 0.625rem;
    }

    .sepertator {
      margin-inline: 0.25rem;
    }
  }
}
