.imagesWrapper {
  height: 200px;
  border-radius: 0.75rem;
  background-color: #fff;
  border: 0.0625rem solid #cacaca;
  margin-bottom: 1.375rem;
  overflow: hidden;

  @media (max-width: 667px) {
    margin-bottom: 1rem;
  }
}

.imageWrapper {
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    max-width: 100%;
    max-height: 100%;
  }
}
