.container {
  font-size: 1rem;
  text-align: center;
  font-family: var(--var-inter);
  font-weight: 400;
  padding: 0.875rem 0;

  a {
    text-decoration: none;
    color: var(--label-text-color);
  }
}
