.error {
  color: #dc3545;
  font-size: 0.875rem;
  font-weight: 500;
  font-family: var(--var-poppins);
  margin-top: 0.3125rem;
  margin-bottom: 0px;
}

.center {
  margin-top: 0.875rem;
  margin-left: 0;
  margin-bottom: 0.875rem;
  text-align: center;
}
