@import "../../scss/mixins";
@import "../../scss/variables";
.wrapper {
  flex: 1;
  padding: 0.75rem 1.5rem;
  padding-top: 0;
  overflow-y: auto;
  &::-webkit-scrollbar {
    display: none;
  }

  .accordionTitle {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.75rem 0;
    border-bottom: 0.0625rem solid #dedede;
    cursor: pointer;
    .left {
      display: flex;
      align-items: center;
      gap: 0.5rem;

      flex: 1;
      max-width: 75%;
      .icon {
        display: flex;
        padding: 0.25rem;
        align-items: center;
        border-radius: 2.375rem;
        background: rgba(var(--primary-color-rgb), 0.1);

        svg {
          path {
            stroke: var(--primary-color);
          }
        }
      }

      p {
        color: var(--text-color);
        font-size: 1.125rem;
        font-weight: 500;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
      }
    }

    .right {
      p {
        color: var(--virtual-text-color);
        font-size: 1rem;
        font-weight: 500;
      }
    }
    &.active {
      border-bottom: none;
    }
  }

  .subAccordionTitle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    p {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      color: var(--text-color);
      font-size: 1rem;
      font-weight: 400;
      flex: 1;
      max-width: 80%;
    }

    &.active {
      padding-bottom: 0.75rem;
    }
    svg {
      path {
        stroke: var(--virtual-text-color);
      }
    }
  }
  .subAccordionContent {
    width: 100%;
    height: 169px;
    border-radius: 0.25rem;
    box-sizing: border-box;
    background-color: #d9d9d9;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .backDrop {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: rgba($color: #000000, $alpha: 0.3);
      z-index: 0;
    }
    .textContainer {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: #fff;

      p {
        font-family: var(--var-inter);
        font-size: 1rem;
        font-weight: 600;
      }
    }
  }

  @include breakpoint(0, $smScreen) {
    @include orientation(landscape) {
      .subAccordionContent {
        width: 50%;
      }
    }
  }
}

.iframe {
  width: 100%;
  height: calc(100% - 52px);
  border: none;
  outline: none;
}
