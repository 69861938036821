.wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  padding: 1.5rem;
}
.tabsContainer {
  .tabs {
    display: flex;
    align-items: center;
  }
}
