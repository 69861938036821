@import "../../../scss/mixins";
@import "../../../scss/variables";

.productDetailsInfo {
  font-family: var(--var-inter);

  .info {
    padding-bottom: 1.375rem;
    position: relative;

    > .discount {
      display: inline-block;
      padding: 0.25rem;
      border-radius: 0.1875rem;
      background: #e44141;
      color: #fff;
      font-size: 0.875rem;
      font-weight: 700;
      margin-bottom: 0.5rem;
    }

    .calls {
      display: flex;
      align-items: center;
      gap: 0.75rem;
      margin-bottom: 1.375rem;

      .showIn360 {
        display: flex;
        padding: 0.25rem 0.5rem;
        justify-content: center;
        align-items: center;
        gap: 0.5rem;
        border-radius: 0.5rem;
        color: var(--primary-color);
        background: rgba(var(--primary-color-rgb), 0.1);
        font-size: 0.8125rem;
        font-weight: 600;
        width: 136px;
        height: 36px;
        box-sizing: border-box;
        cursor: pointer;

        svg {
          * {
            stroke: var(--primary-color);
          }
        }
      }
      .videoCallStore {
        display: flex;
        padding: 0.25rem 0.5rem;
        justify-content: center;
        align-items: center;
        gap: 0.5rem;
        border-radius: 0.5rem;
        border: 0.0625rem solid
          var(--background-color-and-transparent-border-color);
        background: var(--background-color-and-transparent);
        color: var(--text-color);
        font-size: 0.8125rem;
        font-weight: 500;
        width: 157px;
        height: 36px;
        box-sizing: border-box;
        cursor: pointer;
        svg {
          path,
          rect {
            stroke: var(--text-color);
          }
        }
      }
    }

    h4 {
      color: var(--header-text-color);
      font-size: 1.25rem;
      font-weight: 600;
      margin-bottom: 0.5rem;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }

    .price {
      color: var(--primary-color);
      font-size: 1.25rem;
      font-weight: 600;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      margin-bottom: 1rem;

      .discount {
        color: #9f9f9f;
        font-weight: 500;
        text-decoration-line: line-through;
        margin-left: 0.5rem;
      }
    }

    &::after {
      content: "";
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 0.0625rem;
      background: var(--hr-color);
    }
    .description {
      color: var(--first-message-text-color);
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.375rem;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 5;
      -webkit-box-orient: vertical;
    }
  }
  @include breakpoint(0, $smScreen) {
    @include orientation(landscape) {
      .info {
        &::after {
          display: none;
        }
      }
    }
  }
}
