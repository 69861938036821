@import "../../scss/mixins";
@import "../../scss/variables";

.wrapper {
  width: 100%;
  height: 100%;
  padding-top: 0;
  box-sizing: border-box;

  .allowNotify {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    font-weight: 600;
    color: white;
    text-align: center;
    font-family: var(--var-poppins);
  }

  .videoChatWrapper {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    overflow-y: auto;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }

    :global {
      .container {
        width: 100%;
        height: 100%;
        #header {
          min-width: initial !important;
          padding: 0 1.25rem !important;
          #navSessionInfo {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            #titleContent {
              max-width: 5.625rem;
              margin: 0;
              padding: 0.125rem 0.9375rem;
              font-size: 0.75rem;
              border-radius: 0.125rem;
            }
          }
          .buttonsContent {
            display: flex;
            min-width: initial !important;
            margin: initial;
            height: 100% !important;
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            top: 400px;
            button {
              flex: initial !important;
              padding: 0.25rem;
              font-size: 0.5rem;
              span {
                svg {
                  font-size: 1.2rem;
                }
              }
            }
          }
        }
        #layout {
          min-width: initial !important;
          #localUser {
            width: 100% !important;
            height: 100% !important;
            .pointer.nickname {
              font-size: 0.8rem !important;
              display: none;
            }

            #statusIcons {
              display: none;
            }
          }
        }
        &:fullscreen .buttonsContent {
          top: 800px;
        }
      }
    }
    @include breakpoint(0, $smScreen) {
      :global {
        .container {
          #header {
            .buttonsContent {
              top: 350px;
            }
          }
        }
      }
      @include orientation(landscape) {
        :global {
          .container {
            #header {
              .buttonsContent {
                top: 150px;
              }
            }
          }
        }
      }
    }
  }
}

.modal {
  padding: 1rem;
  border-radius: 0.75rem;
  background-color: #fff;
  text-align: center;
  max-width: 70%;

  p {
    font-size: 1rem;
    font-family: var(--var-poppins);
    margin-bottom: 0.5rem;
  }

  button {
    outline: none;
    border: none;
    color: #fff;
    display: block;
    font-size: 0.9375rem;
    font-weight: 500;
    margin-top: 1.125rem;
    margin-bottom: 0.5rem;
    border-radius: 0.75rem;
    font-family: var(--var-poppins);
    background-color: var(--primary-color);
    padding: 0.75rem 1.125rem;
    cursor: pointer;
    margin: auto;
    transition: background-color 0.5s ease-in-out;

    &:hover {
      background-color: var(--primary-dark-color);
    }
  }
}
