.cardContainer {
  width: 118px;
  height: 190px;
  display: flex;
  border: 1px solid #dedede;
  padding: 12px;
  border-radius: 10px;
  flex-direction: column;
  gap: 12px;
  font-family: var(--var-inter);

  .cardHeader {
    width: 100%;
    height: 129px;
    position: relative;
    overflow: hidden;
    background-color: white;
    border-radius: 8px;

    p {
      position: absolute;
      right: 0;
      top: 0;
      z-index: 2;
      color: white;
      background: #e44141;
      font-size: 12px;
      font-weight: 600;
      line-height: 14.52px;
      text-align: right;
      padding: 4px;
      text-transform: uppercase;
    }

    .selectedIcon {
      position: absolute;
      left: 0;
      top: 0;
      z-index: 2;
    }

    .linkContainer {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        border: 1px solid #DEDEDE;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 2;
        display: flex;
        justify-content: center;
        align-items: center;
        background: white;
        transition: all 0.3s ease-in-out;

        &:hover {
            background: #f4f4f4;
        }
    }

    a {
        position: absolute;
        left: 0;
        top: 0;
        z-index: 2;
    }

    img {
      object-fit: contain;
      width: 100%;
      height: 100%;
      z-index: 1;
      background: #fff;
    }
  }

  .cardFooter {
    display: flex;
    flex-direction: column;
    gap: 8px;

    .productName {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      word-break: break-all;
      max-width: 100%;
      font-family: var(--var-inter);
      font-size: 16px;
      font-weight: 500;
      line-height: 19.36px;
      text-align: left;
      color: #323232;
    }

    .productPrice {
      font-size: 14px;
      font-weight: 500;
      line-height: 16.94px;
      text-align: left;
      color: var(--primary-color);
      display: flex;
      gap: 5px;

      span {
        margin-left: 2px;
      }

      .discount {
        text-decoration: line-through;
        color: #9f9f9f;
      }
    }
  }
}

.pointer {
  cursor: pointer;
}
