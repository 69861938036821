.container {
  button {
    margin-top: 4.375rem;
  }
  .backToLogin {
    padding: 0.875rem 0;
    color: var(--label-text-color);
    font-family: var(--var-inter);
    font-size: 1rem;
    font-weight: 400;
    text-align: center;
    text-decoration: none;
  }
}
