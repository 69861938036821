.avatarGroup
{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 64px;

    img,
    span
    {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 64px;
        height: 64px;
        background-color: var(--main-background-color);
        border-radius: 50%;
        box-sizing: border-box;
        border: 0.25rem solid var(--primary-color);
        margin-left: -1.625rem;
        font-family: var(--var-inter);

        &:first-child {
            margin-left: 0;
        }
    }

    span
    {
        color: var(--text-color);
        text-transform: uppercase;
        font-size: 1rem;
        font-weight: 600;
        font-family: Arial, Helvetica, sans-serif;
    }

    .noActiveUsers {
        color: #fff;
        font-family: var(--var-inter);
        font-size: 0.875rem;
        font-weight: 500;
    }
}
